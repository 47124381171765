var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"receipt-view",attrs:{"fluid":"","size":"large"}},[_c('v-card',{staticStyle:{"width":"100%"},attrs:{"flat":""}},[_c('v-app-bar',{staticClass:"pl-0",attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-card-title',{staticClass:"pl-0",staticStyle:{"padding-left":"-16px"}},[_vm._v(" Billing Details "),_c('v-tooltip',{attrs:{"top":"","max-width":"200","color":"#484A50","content-class":"donorCountTooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"fa-regular fa-circle-info ml-1",attrs:{"small":""}},'v-icon',attrs,false),on))]}}])},[_c('span',[_vm._v("If there is a not a receipt for a given month, then you did not process any donation and have a bill of $0.")])])],1),_c('v-spacer')],1),_c('v-data-table',{ref:"receiptTable",staticClass:"elevation-1 mx-4 ml-0 mr-0",attrs:{"headers":_vm.columns,"items":_vm.items,"sort-desc":[false, true],"multi-sort":"","loading":_vm.isLoading,"loading-text":"Fetching your receipts..."},scopedSlots:_vm._u([{key:"item.gross",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(item.gross))+" ")]}},{key:"item.stripeFees",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(item.stripeFees))+" ")]}},{key:"item.roundupFees",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(item.roundupFees))+" ")]}},{key:"item.net",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat( item.gross - item.stripeFees - item.roundupFees ))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('Roundup-Button',{staticStyle:{"min-width":"40px !important","height":"25px !important"},attrs:{"color":"white"},on:{"click":function($event){return _vm.onRequestReceiptPDF(item)}}},[_c('i',{staticClass:"fa-regular fa-download"})])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }