<template>
	<v-container fluid class="receipt-view" size="large">
		<v-card flat style="width: 100%">
			<v-app-bar flat color="rgba(0, 0, 0, 0)" class="pl-0">
				<v-card-title class="pl-0" style="padding-left: -16px">
					Billing Details
					<v-tooltip
						top
						max-width="200"
						color="#484A50"
						content-class="donorCountTooltip"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								v-bind="attrs"
								v-on="on"
								small
								class="fa-regular fa-circle-info ml-1"
							></v-icon>
						</template>
						<span
							>If there is a not a receipt for a given month, then
							you did not process any donation and have a bill of
							$0.</span
						>
					</v-tooltip>
				</v-card-title>

				<v-spacer></v-spacer>
			</v-app-bar>
			<v-data-table
				:headers="columns"
				:items="items"
				:sort-desc="[false, true]"
				multi-sort
				class="elevation-1 mx-4 ml-0 mr-0"
				:loading="isLoading"
				loading-text="Fetching your receipts..."
				ref="receiptTable"
			>
				<template v-slot:item.gross="{ item }">
					{{ currencyFormat(item.gross) }}
				</template>
				<template v-slot:item.stripeFees="{ item }">
					{{ currencyFormat(item.stripeFees) }}
				</template>
				<template v-slot:item.roundupFees="{ item }">
					{{ currencyFormat(item.roundupFees) }}
				</template>
				<template v-slot:item.net="{ item }">
					{{
						currencyFormat(
							item.gross - item.stripeFees - item.roundupFees
						)
					}}
				</template>
				<template v-slot:item.actions="{ item }">
					<Roundup-Button
						color="white"
						style="min-width: 40px !important; height: 25px !important"
						@click="onRequestReceiptPDF(item)"
						><i class="fa-regular fa-download"></i
					></Roundup-Button>
				</template>
			</v-data-table>
		</v-card>
	</v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { get, isEmpty, currencyFormat } from '@/utilities'
import table from '@/mixins/table'
import UiButton from '../../ui/Button'
import UiLoader from '../../ui/Loader'
import Screen from '../../ui/Screen'
import Modal from '../../ui/Modal'

export default {
	name: 'receipt-list-view',
	components: { UiButton, Screen, Modal, UiLoader },
	mixins: [table],
	computed: {
		...mapState({
			items: ({ receiptList }) => receiptList.items,
			isLoading: ({ receiptList }) => receiptList.isFetching,
			total: ({ receiptList }) => receiptList.total,
			error: ({ receiptList }) => receiptList.error
		})
	},
	data() {
		return {
			columns: [
				{ text: 'Month', value: 'long_date' },
				{ text: 'Donation Count', value: 'donationCount' },
				{ text: 'Gross Donations', value: 'gross' },
				{ text: 'Credit Card Fees', value: 'stripeFees' },
				{ text: 'RoundUp Fees', value: 'roundupFees' },
				{ text: 'Net Donations', value: 'net' },
				{ text: ' ', value: 'actions' }
			]
		}
	},
	methods: {
		...mapActions(['getReceipts', 'requestReceiptPDF']),
		currencyFormat,
		async onRequestReceiptPDF(item) {
			this.requestReceiptPDF(item.date).then(data => {
				window.open(data, '_blank')
			})
		}
	},
	mounted() {
		this.getReceipts()
	}
}
</script>

<style lang="scss" scoped>
@import '../../../styles/variables';

.v-data-table {
	::v-deep .v-data-table-header {
		background-color: $roundup-bone !important;
		text-transform: uppercase !important;
	}
}
</style>
